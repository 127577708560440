import React, { useState, useContext } from 'react';
import '../styles/Header.css';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { logoutUser, user } = useContext(AuthContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (pageURL) => {
    setMenuAnchorEl(null);
    if(pageURL) {
      window.location.href = pageURL;
    } 
  };

  const navigate = useNavigate();

  const goToHome = () => {
    navigate(`/`);
  }

  return (
    <header>
      <div className="container">
        <div onClick={goToHome} className="title">
          <h1>TT Alert</h1>
        </div>
        <div className="menu">
          <div className="menu-icon">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={() => handleMenuClose()}
          >
            <MenuItem onClick={() => handleMenuClose('/')}>Home</MenuItem>
            <MenuItem onClick={() => handleMenuClose('/store')}>Store</MenuItem>
            {!user && <MenuItem onClick={() => handleMenuClose('/login')}>Login</MenuItem>}
            {user && <MenuItem onClick={() => handleMenuClose('/profile')}>Profile</MenuItem>}
            {user && <MenuItem onClick={logoutUser}>Logout</MenuItem>}
          </Menu>
          </div>
          <div className="desktop-menu">
            <a href="/" className="menu-item">Home</a>
            <a href="/store" className="menu-item">Store</a>
            {!user ? (
              <a href="/login" className="menu-item">Login</a>
            ) : (
              <>
                <a href="/profile" className="menu-item">Profile</a>
                <a href="/login" onClick={logoutUser} className="menu-item logout">Logout</a>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
