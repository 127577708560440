// DeviceMap.jsx

import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { reverseGeocode } from '../utils/utils';

// Fix for default icon issue in Leaflet when using Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const DeviceMap = ({ initialGps, onLocationSelect }) => {
  const [markerPosition, setMarkerPosition] = useState(initialGps.split(', ').map(Number));

    const handleLocationSelect = async (gps) => {
        const [lat, lng] = gps.split(', ');
        const address = await reverseGeocode(lat, lng);
        onLocationSelect(gps, address);
    };

  const MapEvents = () => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setMarkerPosition([lat, lng]);
        handleLocationSelect(`${lat}, ${lng}`);
      },
    });
    return null; // Component without a render
  };

  return (
    <MapContainer center={markerPosition} zoom={13} style={{ height: 300, width: '100%' }} scrollWheelZoom={true}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution=""
      />
      <Marker position={markerPosition} />
      <MapEvents />
    </MapContainer>
  );
};

export default DeviceMap;
