import React, { useState, useRef, useContext, useEffect } from "react";
import "../styles/Login.css"; // Assuming you want to reuse the Login styles
import Header from "./Header";
import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const Registration = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (user) {
      console.log("User is logged in:", user);
      navigate("/"); // Redirect logged-in users to home
    }
  }, [user, navigate]);

  const handleRegistration = async (event) => {
    setErrorMessage("");
    event.preventDefault();
    setIsLoading(true);

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const userIp = ipData.ip;

    const url = `${process.env.REACT_APP_BASE_API_URL}/api/users/register/`;
    const data = { email, password, ip: userIp };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setIsLoading(false);
        throw new Error(errorData.error || "Registration failed");
      }

      const responseData = await response.json();
      console.log("Registration successful:", responseData);
      event.target.reset();
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setRegistrationSuccess(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Registration error:", error.message);
      setIsLoading(false);
      setErrorMessage(error.message);
      event.target.reset();
    }
  };

  return (
    <div>
      <Header />
      <div className="page-container">
        {registrationSuccess ? (
          <div className="registration-success-container">
            <div className="registration-success">
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark-circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark-check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
              <h2>Registration Successful!</h2>
              <p>Please check your email to verify your account.</p>
              <Link to="/login" className="success-login-link">
                Go to Login
              </Link>
            </div>
          </div>
        ) : (
          <div className="login-container">
            <div className="title-container">
              <h1 className="login-title">Register</h1>
              <p className="message">Create your account</p>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <form onSubmit={handleRegistration} ref={formRef} className="form">
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="input-group input-group-password">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  minLength={8}
                  required
                />
              </div>

              <div className="input-group input-group-password">
                <label htmlFor="confirm-password">Confirm Password</label>
                <input
                  type="password"
                  id="confirm-password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  minLength={8}
                  required
                />
              </div>

              <div className="input-group input-group-terms">
                <input type="checkbox" id="terms" name="terms" required />
                <label htmlFor="terms">
                  I agree to the Terms and Conditions
                </label>
              </div>

              <button
                type="submit"
                className="login-button"
                disabled={isLoading}
              >
                {isLoading ? "Registering..." : "Register"}
              </button>

              <div className="login-redirect">
                <p>
                  Already have an account?{" "}
                  <Link to="/login" className="login-link">
                    Login
                  </Link>
                </p>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Registration;
