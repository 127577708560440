import React from 'react';
import Header from './Header';

const Home = () => {

  return (
    <div>
      < Header />
      <div className='page-container'>
      <h1>Home</h1>
    </div>
    </div>
  )
}

export default Home
