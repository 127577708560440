import React, { useState, useEffect, useContext } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import "../styles/EmailVerification.css";
import AuthContext from "../context/AuthContext";

const EmailVerification = () => {
  const { user } = useContext(AuthContext);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      console.log("User is logged in:", user);
      navigate("/"); // Redirect logged-in users to home
    }
  }, [user, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    setToken(token);
  }, []);

  useEffect(() => {
    if (token === null) {
      return;
    }

    console.log("EmailVerification component mounted");
    const verifyEmail = async () => {
      // Extract the token from URL query params
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/api/users/verify-email/?token=${token}`,
          {
            method: "GET",
          }
        );

        const data = await response.json(); // Parse JSON body
        console.log("data", data);
        if (response.ok) {
          setVerificationStatus("success");
        } else {
          setVerificationStatus("failure");
          // Use the custom error message from the backend
          setErrorMessage(data.error || response.statusText); // Fallback to statusText if no error message
        }
      } catch (error) {
        console.error("Verification error:", error);
        setVerificationStatus("failure");
        setErrorMessage("An unexpected error occurred. Please try again."); // Fallback error message
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div>
      <Header />
      <div className="page-container">
        <div className="verification-container">
          {verificationStatus === "success" ? (
            <div className="verification-success">
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark-circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark-check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
              <h2>Email Verified Successfully!</h2>
              <p>Your email has been verified. You can now login.</p>
              <Link to="/login" className="success-login-link">
                Go to Login
              </Link>
            </div>
          ) : verificationStatus === "failure" ? (
            <div className="verification-failure">
              <svg
                className="crossmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="crossmark-circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="crossmark-cross"
                  fill="none"
                  d="M16 16l20 20m-20 0l20-20"
                />
              </svg>
              <h2>Email Verification Failed</h2>
              <h4>{errorMessage || "An unexpected error occurred."}</h4>
              <p>Please try again or contact support.</p>
              <Link to="/contact" className="failure-contact-link">
                Contact Support
              </Link>
            </div>
          ) : (
            <p>Verifying...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
