import React, { useEffect, useState, useMemo, useRef } from "react";
import Header from "./Header";
import Device from "./Device";
import InputMask from 'react-input-mask';

import { getHeaders, formatPhoneNumber } from "../utils/utils";

import "../styles/UserProfile.css";

const UserProfile = () => {
  const [open, setOpen] = useState(false);
  const [currentDeviceId, setCurrentDeviceId] = useState(null);
  const [devices, setDevices] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [deviceStatuses, setDeviceStatuses] = useState({});
  const [userDetails, setUserDetails] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [phone, setPhone] = useState("");

  const formRef = useRef(null);

  const deviceIds = useMemo(
    () => devices.map((device) => device.device_id),
    [devices]
  );

  const handleEditClick = (event, deviceId) => {
    setOpen(true);
    console.log("Device ID:", deviceId);
    setCurrentDeviceId(deviceId);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/api/users/profile`,
          {
            method: "GET",
            headers: getHeaders(),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setUserDetails(result);
      } catch (error) {
        console.error("Could not fetch user details:", error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/api/devices`,
          {
            method: "GET",
            headers: getHeaders(),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        setDevices(result);
      } catch (error) {
        console.error("Could not fetch devices:", error);
      }
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    console.log("Devices:", devices);
  }, [devices]);

  useEffect(() => {
    setDeviceList(
      devices.map((device, index) => {
        const status = deviceStatuses[device.device_id] || "Unknown";
        return (
          <div key={device.device_id} className="device">
            <div className="device-details">
              <h3>{device.name || `Device ${index + 1}`}</h3>
              <p>{device.mac_address}</p>
              <div className="device-status">
                <p>Status: </p>
                <p className={status?.toLowerCase()}>{status}</p>
              </div>
            </div>
            <div className="device-buttons">
              <button onClick={(e) => handleEditClick(e, device.device_id)}>
                Edit
              </button>
            </div>
          </div>
        );
      })
    );
  }, [devices, deviceStatuses]); // Include deviceStatuses in the dependency array

  useEffect(() => {
    const fetchDeviceStatuses = async () => {
      if (deviceIds.length === 0) return;

      const queryParams = deviceIds.map((id) => `device_ids=${id}`).join("&");
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/checkHeartbeat?${queryParams}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: getHeaders(), // Using getHeaders() to include the necessary authentication headers
        });
        if (!response.ok) throw new Error("Network response was not ok");

        const statuses = await response.json();
        setDeviceStatuses(statuses);
      } catch (error) {
        console.error("Failed to fetch device statuses:", error);
      }
    };

    fetchDeviceStatuses();
    // Set up an interval to fetch device statuses every 30 seconds
    const intervalId = setInterval(fetchDeviceStatuses, 30000);

    // Clean up the interval when the component unmounts or deviceIds changes
    return () => clearInterval(intervalId);
  }, [deviceIds]);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit action

    // Gather form data
    const formData = {
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      phone: event.target.phone.value.replace(/\D/g, ''),
    };

    if (Object.values(formData).every(x => (x === null || x === ''))) {
      alert("Please fill in at least one field to update your profile.");
      return;
    }

    const url = `${process.env.REACT_APP_BASE_API_URL}/api/users/profile/update/`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: getHeaders(), // Using getHeaders() to include the necessary authentication headers
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        let updatedUserDetails = { ...userDetails };
        for (let key in formData) {
          if (
            formData[key] !== undefined &&
            formData[key] !== null &&
            formData[key] !== ""
          ) {
            updatedUserDetails[key] = formData[key];
          }
        }
        console.log("Updated User Details:", updatedUserDetails);
        setUserDetails(updatedUserDetails);
        console.log("Profile updated successfully");
        event.target.reset();
        setPhone("");
        formRef.current.focus();
        formRef.current.blur();
      } else {
        // Handle errors, e.g., showing an error message
        console.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Failed to fetch device statuses:", error);
    }

  };

  return (
    <div>
      <Header />
      <div className={`device-popover ${open ? "open" : ""}`}>
        <Device
          key={currentDeviceId}
          device={devices?.find(
            (device) => device.device_id === currentDeviceId
          )}
          handleClose={handleClose}
        />
      </div>
      <div className="profile-page-container">
        <div className="user-profile">
          <div className="user-details-devices">
            <div className="user-info-container">
              <div className="user-avatar">
                <img src="https://i.pravatar.cc/100" alt="user" />
              </div>
              <div className="user-details">
                <h2>{`${userDetails.firstName} ${userDetails.lastName}`}</h2>
                <p>{userDetails.email}</p>
              </div>
            </div>
            <div className="device-info-container">
              <div className="device-title">
                <h2>Devices</h2>
                <p>Your registered devices</p>
              </div>
              <div className="device-list">{deviceList}</div>
            </div>
          </div>
          <div className="user-change-details">
            <div className="form-container">
              <div className="form-header">
                <h2>Profile</h2>
                <p>Update your profile information.</p>
              </div>
              <form onSubmit={handleSubmit} ref={formRef}>
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder={`${userDetails.firstName}`}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder={`${userDetails.lastName}`}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={userDetails.email}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <InputMask
                    mask="(999) 999-9999"
                    placeholder={formatPhoneNumber(userDetails.phone)}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    id="phone"
                    name="phone"
                  />
                </div>
                <div className="form-group form-button">
                  <button type="submit" className="submit-btn">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
