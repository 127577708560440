import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import "../styles/Login.css";
import Header from "./Header";


const Login = () => {
  let { loginUser, user } = useContext(AuthContext);
  let navigate = useNavigate();
  let location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const passwordRef = useRef(null);

  // Check if there's a redirection path; if not, default to "/"
  let { from } = location.state || { from: { pathname: "/" } };

  console.log("locationstate", location.state, from);

  useEffect(() => {
    if (user) {
      navigate(from.pathname);
    }
  }, [user, navigate, from.pathname]);

  const handleLogin = (e) => {
    setErrorMessage("");
    e.preventDefault();
    loginUser(e, from).catch(error => {
      setErrorMessage(error.message);
      passwordRef.current.value = "";
    });
  };
  return (
    <div>
      <Header />
      <div className="page-container">
        <div className="login-container">
          <div className="title-container">
            <h1 className="login-title">Login</h1>
            <p className="message">
              Enter your email below to login to your account
            </p>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <form onSubmit={handleLogin} className="form">
            <div className="input-group">
              <label for="email">Email</label>
              <input type="email" name="email" id="email" />
            </div>

            <div class="input-group input-group-password">
              <label for="password">Password</label>
              <input ref={passwordRef} type="password" id="password" name="password" />
              <a className="forgot-password" href="/forgot-password">
                Forgot Password?
              </a>
            </div>

            <button type="submit" className="login-button">
              Login
            </button>

            <div className="login-redirect">
              <p>Create an account? <Link to="/register" className="login-link">Register</Link></p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
