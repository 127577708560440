import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from './components/Home';
import Login from './components/Login';
import NotFound from './components/NotFound';
import UserProfile from './components/UserProfile';
import Store from './components/Store';
import Registration from './components/Registration';
import EmailVerification from "./components/EmailVerified";

import PrivateRoutes from "./utils/PrivateRoutes";
import { AuthProvider } from "./context/AuthContext";

const App = () => {
  
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route element={<PrivateRoutes />} >
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<UserProfile />}  />
            <Route path="/store" element={<Store />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
