import React, { useEffect, useState } from "react";
import "../styles/Device.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { getHeaders, formatGPS } from "../utils/utils";
import DeviceMap from "./DeviceMap";

const Device = ({ device, handleClose }) => {
  const [deviceGroups, setDeviceGroups] = useState([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [editedName, setEditedName] = useState(device?.name);
  const [editedLocationGPS, setEditedLocationGPS] = useState("");
  const [editedLocationAddress, setEditedLocationAddress] = useState({});

  useEffect(() => {
    setDeviceGroups([
      { id: 1, name: "Family" },
      { id: 2, name: "Friends" },
    ]);
  }, [setDeviceGroups]);

  const saveDeviceName = async () => {
    if (editedName.length > 10) {
      alert("Name cannot be longer than 10 characters.");
      return;
    }
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/device/${device.device_id}/name`;
    const requestOptions = {
      method: "PATCH",
      headers: getHeaders(),
      body: JSON.stringify({ name: editedName }),
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) throw new Error("Network response was not ok");
      console.log("Device name updated successfully");
      console.log(editedName);
      device.name = editedName;
      setIsEditingName(false);
    } catch (error) {
      console.error("Failed to update device name:", error);
    }
  };

  const saveDeviceLocation = async () => {
    console.log("Saving device location");
    
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/device/${device.device_id}/location`;
    const requestOptions = {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify({
        gps: editedLocationGPS,
        address: editedLocationAddress,
      }),
    };
  
    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) throw new Error("Network response was not ok");
      console.log("Device location updated successfully");
      // Here, handle the successful update, e.g., by updating the state or showing a message
    } catch (error) {
      console.error("Failed to update device location:", error);
    }
    setIsEditingLocation(false);
  };
  

  // Create a template for alert types
  const alertTypes = ["Police", "Fire", "Medical", "Alarm"];

  // If less than four groups, add placeholders
  const filledDeviceGroups = [...deviceGroups];
  for (let i = filledDeviceGroups.length; i < 4; i++) {
    filledDeviceGroups.push({ id: i + 1, name: "" });
  }

  return (
    <div className="device-card">
      <div className="card-header">
        <div></div>
        {isEditingName ? (
          <>
            <input
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              maxLength={10}
            />
            <IconButton onClick={saveDeviceName} style={{ fontSize: "10px" }}>
              <CheckIcon style={{ fontSize: "20px" }} />
            </IconButton>
            <IconButton
              onClick={() => setIsEditingName(false)}
              style={{ fontSize: "20px" }}
            >
              <CancelIcon style={{ fontSize: "20px" }} />
            </IconButton>
          </>
        ) : (
          <>
            <h2>{device?.name || `No Name`}</h2>
            <IconButton
              onClick={() => setIsEditingName(true)}
              style={{ fontSize: "20px" }}
            >
              <EditIcon style={{ fontSize: "20px" }} />
            </IconButton>
          </>
        )}
        <div className="close-button">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className="contact-groups">
        <div className="contact-group">
          {alertTypes.map((type) => (
            <p className="alert-type" key={type}>
              {type}
            </p>
          ))}
        </div>
        {filledDeviceGroups.map((group, index) => (
          <div className="contact-group" key={group.id}>
            {group.name ? (
              <h3>{group.name}</h3>
            ) : (
              <button className="group-add" size="small">
                Add
              </button>
            )}
            {alertTypes.map((type) => (
              <input
                type="checkbox"
                key={type + group.id}
                id={type.toLowerCase() + "-" + group.id}
                name={type.toLowerCase() + "-" + group.id}
                disabled={!group.name} // Disable if no group name
              />
            ))}
          </div>
        ))}
      </div>
      <div className="location-input">
        <div className="location-header">
          <h3>Location</h3>
          {isEditingLocation ? (
            <>
              <IconButton
                onClick={saveDeviceLocation}
                style={{ fontSize: "10px" }}
              >
                <CheckIcon style={{ fontSize: "20px" }} />
              </IconButton>
              <IconButton
                onClick={() => {setIsEditingLocation(false); setEditedLocationGPS(""); setEditedLocationAddress("")}}
                style={{ fontSize: "20px" }}
              >
                <CancelIcon style={{ fontSize: "20px" }} />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                onClick={() => setIsEditingLocation(true)}
                style={{ fontSize: "20px" }}
              >
                <EditIcon style={{ fontSize: "20px" }} />
              </IconButton>
            </>
          )}
        </div>
        <p className="device-address">
            {(editedLocationAddress?.full_address || device?.address?.full_address || "No address").replace(/,/g, ',\u200B')}
        </p>
        <p className="device-gps">
          {editedLocationGPS ? formatGPS(editedLocationGPS) : (device?.address?.gps && formatGPS(device.address.gps))}
        </p>
        {isEditingLocation && (
            <DeviceMap
                initialGps={device?.address?.gps || "0, 0"}
                onLocationSelect={(newGps, address) => {
                    console.log("New GPS coordinates:", newGps);
                    setEditedLocationGPS(newGps);
                    setEditedLocationAddress(address);
                }}
            />
            )}
      </div>
    </div>
  );
};

export default Device;
