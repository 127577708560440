import React from 'react';
import '../styles/Store.css';
import Header from './Header';

const Pricing = () => {
  const plans = [
    { name: 'Basic', price: '29', features: ['720p Video Rendering', '2GB Cloud Storage', 'Basic Video Templates'], popular: false },
    { name: 'Pro', price: '59', features: ['1080p Video Rendering', '10GB Cloud Storage', 'Premium Video Templates', 'Collaboration Tools'], popular: true },
    { name: 'Enterprise', price: '99', features: ['4K Video Rendering', 'Unlimited Cloud Storage', 'Custom Video Templates', 'Advanced Collaboration Tools', 'Dedicated Support'], popular: false },
  ];

  return (
  <div>
    <Header />
    <div className="pricing-container">
      
      {plans.map(plan => (
        <div className={`card ${plan.popular ? 'popular' : ''}`} key={plan.name}>
          {plan.popular && <div className="badge">Popular</div>}
          <h2>{plan.name}</h2>
          <p className="price">${plan.price} / month</p>
          <ul>
            {plan.features.map(feature => (
              <li key={feature}>{feature}</li>
            ))}
          </ul>
          <div className="button-container">
            <button className="get-started">Get Started</button>
          </div>
        </div>
      ))}
    </div></div>
  );
}

export default Pricing;

