import React from 'react'
import '../styles/NotFound.css'

const NotFound = () => {
    return (
        <div className="not-found-container">
            <h1 className="error-code">404</h1>
            <p className="error-message">Page Not Found</p>
        </div>
    )
}

export default NotFound