import React, { createContext, useState, useEffect, useCallback, useMemo } from "react";
import {jwtDecode} from "jwt-decode";
import { useNavigate } from "react-router";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwtDecode(localStorage.getItem("authTokens"))
      : null
  );
  let navigate = useNavigate();
  let loginUser = useCallback(async (e, locationState) => {
    e.preventDefault();
  
    let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.target.email.value,
        password: e.target.password.value,
      }),
    });
  
    let data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      console.log(locationState);
      // Navigate to the previous location or default to home page if not available
      navigate(locationState?.pathname || '/');
    }
    else {
      throw new Error("Invalid Credentials");
    }
  }, [setAuthTokens, setUser, navigate]);

  let logoutUser = useCallback(() => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    if (window.location.pathname !== '/') {
      navigate("/");
    }
  }, [setAuthTokens, setUser, navigate]);

  let updateToken = useCallback(async () => {
    console.log("Update Token Called");
    let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: authTokens.refresh,
      }),
    });
  
    let data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));      
    } else {
      logoutUser();
    }
  }, [authTokens?.refresh, setAuthTokens, setUser, logoutUser]);

  let contextData = useMemo(() => ({
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
  }), [user, loginUser, logoutUser]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, 1000 * 60 * 4);
    return () => clearInterval(interval);
  }, [authTokens, updateToken]);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};