function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export { toTitleCase };

function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("authTokens")).access
    }`,
  };
}

export { getHeaders };

const formatGPS = (gps) => {
  return gps
    .split(", ")
    .map((coord) => {
      // Convert to float, round to 6 decimal places, and convert back to string
      return parseFloat(coord).toFixed(6);
    })
    .join(", ");
};

export { formatGPS };

const reverseGeocode = async (lat, lon) => {
  const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    console.log(data);
    const {
      address: {
        house_number: civic_number = '',
        road: streetName = '',
        state = '',
        city = '',
        postcode: postal_code = '',
      } = {},
    } = data;
    
    const street = [civic_number, streetName].filter(Boolean).join(' '); // Join with a space if both parts are present
    // Construct the full address string
    const parts = [street, city, state, postal_code].filter(Boolean); // Remove empty values
    const full_address = parts.join(', ').trim(); // Join with commas and trim any excess whitespace

    const structuredAddress = {
      street,
      state,
      city,
      postal_code,
      gps: `${lat}, ${lon}`, // Include GPS coordinates
      full_address, // Add the full address string
    };

    return structuredAddress;
  } catch (error) {
    console.error("Error fetching address:", error);
    return null; // Return null or an empty object to indicate failure
  }
};

export { reverseGeocode };


const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return "";

  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumberString; 
};

export { formatPhoneNumber };
