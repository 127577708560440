// PrivateRoutes.js

import { Outlet, Navigate, useLocation } from "react-router-dom";
import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoutes = () => {
  let { user } = useContext(AuthContext);
  let location = useLocation();

  console.log(location.state);

  return user ? <Outlet/> : <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoutes;